import { put, call } from "redux-saga/effects"
import { push } from 'connected-react-router'

import * as actions from "../actions"
import { removeAllProductsFromShoppingCart } from "../actions";

export function* SubmitOrderAndGoToDetails({ payload }) {

    try {
        const result = yield call(fetch, "/api/orders", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "de-DE",
                "x-auth-token": payload.token
            },
            body: JSON.stringify(payload)
        });

        let orderId = yield result.json()
        
        yield put(removeAllProductsFromShoppingCart())

        yield put(push(`/profile/orders/${orderId.id}`))

    } catch (e) {

    }
}

export function* SubmitOrder({ payload }) {
    try {

        const result = yield call(fetch, "/api/orders", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "de-DE",
                "x-auth-token": payload.token
            },
            body: JSON.stringify(payload)
        });

        if (result.status === 500) {

            let contentType = result.headers.get("content-type");

                if (contentType && contentType.includes("application/json")) {
                    let response = yield result.json();
                    console.error('Error on saving order, please check 3', response, JSON.stringify(response), JSON.stringify(payload));
                // handle JSON
                } else {
                    let text = yield result.text();
                    console.error('Error on saving order, please check 4', text, JSON.stringify(payload));
                }

            //alert('Oh – es ist ein Fehler aufgetreten, bitte lade die Seite neu und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, melde dich doch bitte via Chat oder Email, damit wir die Bestellung aufnehmen können :)');

            /*yield put({ 
                type: 'SUBMIT_ORDER_FAILED', 
            });*/
        } else {

            let response = yield result.json()

            yield put({ 
                type: 'SUBMIT_ORDER_SUCCESS', 
                payload: response
            });  
        }


    } catch (e) {
        console.error('Error on saving order, please check 5', e, JSON.stringify(payload));
    }
}

export function* GetOrders({ token, dataState }) {

    yield put(actions.getOrdersStart());

    try {
        const response = yield call(fetch, '/api/orders/orders', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "de-DE",
                "x-auth-token": token
            },
            body: JSON.stringify(dataState)
        });

        const orders = yield response.json();

        yield put(actions.getOrdersSuccess(
            orders.orders,
            dataState,
            orders.total)
        );

    } catch (e) {
        console.log(e)
        yield put(actions.getOrdersFail(e));
        return;
    }
}

export function* GetAdminOrders({ token, dataState }) {

    yield put(actions.getOrdersStart());

    try {
        const response = yield call(fetch, '/api/orders/admin', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "de-DE",
                "x-auth-token": token
            },
            body: JSON.stringify(dataState),
        });

        const orders = yield response.json();

        yield put(actions.getOrdersSuccess(orders.orders, dataState, orders.total));

    } catch (e) {
        yield put(actions.getOrdersFail(e));
        return;
    }
}

export function* getOrderDetails({ token, id }) {

    yield put(actions.getOrdersStart());

    try {
        const response = yield call(fetch, `/api/orders/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "de-DE",
                "x-auth-token": token
            }
        });

        const order = yield response.json();

        yield put(actions.getOrderDetailsSuccess(order));

    } catch (e) {
        yield put(actions.getOrderDetailsFail(e));
        return;
    }

}


export function* getAdminOrderDetails({ token, id }) {

    yield put(actions.getOrdersStart());

    try {
        const response = yield call(fetch, `/ api / orders / admin / ${id} `, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "de-DE",
                "x-auth-token": token
            }
        });

        const order = yield response.json();

        yield put(actions.getOrderDetailsSuccess(order));

    } catch (e) {
        yield put(actions.getOrderDetailsFail(e));
        return;
    }

}

export function* orderStatusChange({ token, id, newStatus }) {
    yield put(actions.orderStatusChangeStart());

    try {
        const response = yield call(fetch, `/ api / orders / admin / ${id} `, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "de-DE",
                "x-auth-token": token
            },
            body: JSON.stringify({ status: newStatus }),
        });

        if (response.status === 200) {
            yield put(actions.orderStatusChangeSuccess(newStatus));
        } else {
            yield put(actions.orderStatusChangeFail("Error"))
        }

    } catch (e) {
        yield put(actions.orderStatusChangeFail(e));
        return;
    }
}